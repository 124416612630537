import React from "react";
import "./Abonnements.css";

function Abonnements() {
  return (
    <div className="abo-main-container">
      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--lignesfixes"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">Abonnements lignes fixes</div>
              <p className="abo-card__text">
                IP<br></br>
                Forfaits de communications fixe et mobile sur mesure<br></br>
                Veille tarifaire
              </p>
              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>

        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--lignesmobiles"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">Abonnements lignes mobiles</div>
              <p className="abo-card__text">
                Création / portabilité ligne mobile <br></br>
                Réseau au choix : Orange, SFR, Bouygues<br></br>
                Offre au compteur ou illimité
              </p>
              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>
      </ul>
      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
            <div class="abo-card__content">
              <div className="abo-card__title">Offres</div>
              <p className="abo-card__text">
                Abonnement téléphonique nu <br></br>
                Abonnement avec Forfaits Fixes et Mobiles <br></br>
                Abonnement Numeris avec communications illimitées <br></br>
                Compte SIP avec communications illimitées<br></br>
                Economisez en cumulant une offre avec un forfait<br></br>
                Forfaits Communications sur mesure
              </p>
            </div>
          </div>
        </li>

        <li className="abo-card__item">
          <div className="abo-card">
            <div class="abo-card__content">
              <div className="abo-card__title">Les plus</div>
              <p className="abo-card__text">
                Veille tarifaire<br></br>
                Forfait modulable à tout moment sans frais<br></br>
                Accès cuivre, non dégroupé<br></br>
                Pas de frais de mise en service<br></br>
                Pas de frais de résiliation<br></br>
                Aucune intervention sur votre installation téléphonique<br></br>
                Raccordement sous 72 heures ouvrées
              </p>
            </div>
          </div>
        </li>
      </ul>

      {/* <div>
        <h1>
          Pour tout engagement de 12 mois, un Smartphone 5" double carte SIM
          OFFERT*
        </h1>
        <p>*Sur étude</p>
      </div> */}
    </div>
  );
}

export default Abonnements;
