import React from "react";
import "./Abonnements.css";

function LigneExpress() {
  return (
    <div className="abo-main-container">
      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--express"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">
                Votre numéro de téléphone en 1H, partout en France
              </div>
              <p className="abo-card__text">
                Création de votre ligne téléphonique ultra-rapide, obtenez vos
                numéros en 1h !
              </p>
              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>

        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--who"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">
                A qui s'adresse cette offre ?
              </div>
              <p className="abo-card__text">
                Aux créateurs d'entreprise<br></br>
                En cas de panne<br></br>
                En cas de déménagement<br></br>
                En cas de surplus d'activité<br></br>
                Aux centres d'appel
              </p>
              <button class="card__btn card__btn--block">
                Exemples
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    // <div className="abo-container">
    //   <div>
    //     <div>
    //       <h1>Votre numéro de téléphone en 1H, partout en France</h1>
    //       <h2>Création de ligne téléphonique</h2>
    //       <p>Obtenez vos numéros de téléphone en 1H</p>
    //     </div>
    //     <div>
    //       <h1>A qui s'adresse cette offre ?</h1>
    //       <p>Aux créateurs d'entreprise</p>
    //       <p>En cas de panne</p>
    //       <p>En cas de déménagement</p>
    //       <p>En cas de surplus d'activité</p>
    //       <p>Aux centres d'appel </p>

    //     </div>
    //   </div>
    // </div>
  );
}

export default LigneExpress;
