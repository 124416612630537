import React from "react";
import "./Abonnements.css";

function InternetFibre() {
  return (
    <div className="abo-main-container">
      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--ftth"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">FTTH : Fibre mutualisée</div>
              <p className="abo-card__text">Fibre mutualisée de 2MBs à 1GBs</p>
              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>
        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--ftto"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">FTTO : Fibre dédiée</div>
              <p className="abo-card__text">Fibre dédiée de 2 MBs à 1 GBs</p>
              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>
        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--adsl"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">Liens ADSL / SDSL</div>
              <p className="abo-card__text">
                ADSL dégroupage partiel / total<br></br>
                VDSL<br></br>
                SDSL de 2 à 8 MBs, GTR* : 2h
              </p>
              <p className="abo-card__subtext">*Garantie de Temps de Rétablissement</p>
              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>
      </ul>
      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
            <div class="abo-card__content">
              <div className="abo-card__title">Les plus</div>
              <p className="abo-card__text">
                Débit selon vos besoins : de 2 MBs à 1 GBs<br></br>
                Un débit dédié exclusivement à votre entreprise, de manière
                permanente<br></br>
                Modem à l'achat, ou en location <br></br>
                Supervision de votre lien 24/7<br></br>
                Opérateur Orange, Bouygues, SFR et DSP*<br></br>
                <p className="abo-card__subtext">*Délégation de service public</p>
              </p>
            </div>
          </div>
        </li>
        <li className="abo-card__item">
          <div className="abo-card">
            <div class="abo-card__content">
              <div className="abo-card__title">Eligibilité</div>
              <p className="abo-card__text">
                Eligibilité GRATUITE, sur simple demande : complétez simplement
                notre formulaire de contact en bas de page.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default InternetFibre;
