import React, { useState } from "react";
import "./Solutionbar.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Outlet } from "react-router-dom";

function Solutionbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const scrollToTop = ()=> {
    window.scrollTo(0, 0, "auto");
   }

   scrollToTop();

  return (
    <div>
      <nav className="solbar">
        <div className="solbar-container">
          <ul className="sol-menu">
            <li className="sol-item">
              <HashLink to="/solutions/abotel#top" className="sol-links">
                Abonnements Téléphoniques
              </HashLink>
            </li>
            <li className="sol-item">
              <HashLink to="/solutions/ipbx#top" className="sol-links">
                IPBX/PABX
              </HashLink>
            </li>
            <li className="sol-item">
              <HashLink to="/solutions/internet#top" className="sol-links">
                Fibre & Internet
              </HashLink>
            </li>
            <li className="sol-item">
              <HashLink to="/solutions/express#top" className="sol-links">
                Création de ligne Express
              </HashLink>
            </li>
            <li className="sol-item">
              <HashLink to="/solutions/centrex#top" className="sol-links">
                Centrex IP
              </HashLink>
            </li>
          </ul>
          {/* {button && <Button link="/sign-up" buttonStyle="btn--outline" buttonSize="btn--medium">SIGN UP</Button>} */}
        </div>
      </nav>
      <Outlet />
    </div>
  );
}

export default Solutionbar;
