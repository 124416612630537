import React, { useState } from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function sendMail(addr, data) {
  fetch(addr, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

function Footer() {
  const addr = "http://146.59.150.202:1235";
  const locaddr = "http://localhost:1235";

  const [invalid, setInvalid] = useState(false);

  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");

  const [inputName, setInputName] = useState("footer-input");
  const [inputEmail, setInputEmail] = useState("footer-input");
  const [inputPhone, setInputPhone] = useState("footer-input");
  const [inputMessage, setInputMessage] = useState("footer-input-message");

  function ValidateEmail(input) {
    var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  function ValidatePhone(input) {
    var validRegex = /^[\+\d]{10,12}$/g;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  function checkFields() {
    if (name === "") {
      setInputName("footer-input invalid-input");
    } else {
      setInputName("footer-input");
    }

    if (!ValidateEmail(email)) {
      setInputEmail("footer-input invalid-input");
    } else {
      setInputEmail("footer-input");
    }

    if (!ValidatePhone(phone)) {
      setInputPhone("footer-input invalid-input");
    } else {
      setInputPhone("footer-input");
    }

    if (message === "") {
      setInputMessage("footer-input-message invalid-input");
    } else {
      setInputMessage("footer-input-message");
    }
  }

  function resetFields() {
    setName("");
    setemail("");
    setphone("");
    setmessage("");
    setInputName("footer-input");
    setInputEmail("footer-input");
    setInputPhone("footer-input");
    setInputMessage("footer-input-message");
  }

  function send(addr, data) {
    if (
      name !== "" &&
      ValidateEmail(email) &&
      ValidatePhone(phone) &&
      message !== ""
    ) {
      sendMail(addr, data);
      resetFields();
      window.alert("Votre message a bien été envoyé !");
      setInvalid(false);
    } else {
      setInvalid(true);
      checkFields();
      window.alert(
        "Veuillez remplir tous les champs avant d'envoyer le formulaire, et fournir une adresse de messagerie ainsi qu'un numéro de téléphone valides."
      );
    }
  }

  return (
    <div id="contact" className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Interressé ? Contactez-nous, nous reviendrons vers vous au plus vite.
        </p>
        <p className="footer-subscription-text"></p>
        <form id="contact_form">
          <div className="input-areas">
            <input
              className={inputName}
              name="name"
              type="name"
              placeholder="Nom"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <input
              className={inputEmail}
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => {
                setemail(event.target.value);
              }}
            />
            <input
              className={inputPhone}
              name="phone"
              type="phone"
              placeholder="Téléphone"
              value={phone}
              onChange={(event) => {
                setphone(event.target.value);
              }}
            />
          </div>
          <textarea
            className={inputMessage}
            name="message"
            rows="5"
            placeholder="Votre message"
            value={message}
            onChange={(event) => {
              setmessage(event.target.value);
            }}
          />

          <Button
            onClick={() => setmessage("")}
            buttonStyle="btn--outline"
            buttonSize="btn--large"
          >
            Effacer
          </Button>
          <Button
            onClick={() =>
              send(addr, {
                name: name,
                email: email,
                phone: phone,
                message: message,
              })
            }
            buttonStyle="btn--secondary"
            buttonSize="btn--large"
          >
            Envoyer
          </Button>
        </form>
      </section>
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="footer-logo">
            <HashLink to="/#home" className="social-logo">
              Pamplemousse Telecom
            </HashLink>
          </div>

          <div class="social-icons">
            <Link
              class="social-icon-link facebook"
              to="/"
              target="_blank"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </Link>
            <Link
              class="social-icon-link instagram"
              to="/"
              target="_blank"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </Link>

            <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="Twitter"
            >
              <i class="fab fa-twitter" />
            </Link>
            <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
