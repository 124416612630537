import React, { useState } from "react";
import './Navbar.css'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Button } from "./Button";


function Navbar() {

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const scrollToBottom = ()=> {
    window.scrollTo({
               top: 100000,
               left: 0,
               behavior: "smooth"
             })
   }

   const scrollToTop = ()=> {
    window.scrollTo(0, 0, "auto");
   }

   const contactFunc = ()=> {
      closeMobileMenu();
      scrollToBottom();
   }

   const solutionFunc = ()=> {
    closeMobileMenu();
    scrollToTop();
 }

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <HashLink to="/#home" className="navbar-logo">
            Pamplemousse Telecom
          </HashLink>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
                <HashLink to='/#home' className="nav-links" onClick={closeMobileMenu}>
                    Accueil
                </HashLink>
            </li>
            <li className="nav-item">
                <HashLink to='/#about' className="nav-links" onClick={closeMobileMenu}>
                    Présentation
                </HashLink>
            </li>
            <li className="nav-item">
                <HashLink to='/solutions/abotel' className="nav-links" onClick={solutionFunc}>
                    Nos Solutions
                </HashLink>
            </li>
            <li className="nav-item">
                <div className="nav-links" onClick={contactFunc}>
                    Contact
                </div>
            </li>
          </ul>
          {/* {button && <Button link="/sign-up" buttonStyle="btn--outline" buttonSize="btn--medium">SIGN UP</Button>} */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
