import React from "react";
import "./About.css";

function About() {
  return (
    <div id="about" className="main-container">
      <h1>Une équipe passionnée de téléphonie</h1>
      <div className="sub-container">
        <p>
          Pamplemousse Telecom est le fruit de 20 ans d'existence et
          d'expérience entre Economictel et Générale de Téléphonie, l'un et
          l'autre inséparables dans le domaine des services de téléphonie fixe
          et mobile et des installations de PABX et IPBX.<br></br>
          De cette indissociable collaboration, les deux sociétés ont cumulées
          un savoir faire et une parfaite maîtrise du monde des Télécoms mis à
          profit auprès des professionnels.<br></br>
          Au fil du temps, ces deux sociétés ont acquis la confiance de
          professionnels dans tous les secteurs d'activités, de toute taille et
          de tous type : établissements scolaires, commerçants, artisans,
          industries, milieu associatif, milieu médical, administrations...
        </p>
        <p>
          Présent physiquement sur toute la France via ses effectifs situés en
          Normandie, Picardie, Centre, Bourgogne et Gironde, Pamplemousse
          Telecom s'appuie sur un réseau de partenaires développé depuis toutes
          ces années.<br></br>
          Son succès lié à la passion de ses effectifs et sa forte réactivité
          lui permettent de répondre à des besoins précis dans des délais très
          courts.
        </p>
      </div>
    </div>
  );
}

export default About;
