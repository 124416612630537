import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home.js";
import Solutionbar from "./components/Solutionbar";
import Abonnements from "./components/Abonnements";
import Ipbxpabx from "./components/Ipbxpabx";
import InternetFibre from "./components/InternetFibre";
import LigneExpress from "./components/LigneExpress";
import CentrexIp from "./components/CentrexIp";


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="solutions" element={<Solutionbar />}>
            <Route path='abotel' element={<Abonnements />} />
            <Route path="ipbx" element={<Ipbxpabx/>} />
            <Route path="internet" element={<InternetFibre />} />
            <Route path="express" element={<LigneExpress />} />
            <Route path="centrex" element={<CentrexIp />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
