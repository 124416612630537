import React from "react";
import "./Abonnements.css";

function CentrexIp() {
  return (
    <div className="abo-main-container">
      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
          <div className="abo-card__image card__image--centrex"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">
                Externalisez votre standard téléphonique
              </div>
              <p className="abo-card__text">
                <b>Découvrez le Centrex IP :</b><br></br>
                Virtualisez votre installation téléphonique <br></br>
                Toute la programmation de votre téléphonie se fait à distance
                <br></br>
                Minimisez au maximum le risque de panne matériel <br></br>
                Pour résumé, le centrex vous offre une vraie tranquillité d'esprit, tout en vous permettant de faire des économies
              </p>
              <button class="card__btn card__btn--block">
                En savoir plus
              </button>
            </div>
          </div>
        </li>
      </ul>
      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
            <div class="abo-card__content">
              <div className="abo-card__title">Les plus</div>
              <p className="abo-card__text">
                Téléphone Plug and Play<br></br>
                Large gamme de téléphone IP<br></br>
                En achat ou en location<br></br>
                License SIP avec communications au compteur, en forfait, ou
                illimitées
                <br></br>
                Portabilité du numéro de téléphone ou création<br></br>
                Nombreuses options d'utilisation : SVI, renvoi, transfert,
                appels simultanés fixe/mobile ...<br></br>
                A partir de 14.90€ HT/mois et par utilisateur<br></br>
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default CentrexIp;
