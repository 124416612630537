import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div id="home" className="hero-container">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Opérateur & Installateur téléphonique privé</h1>
      <p>Simple, clair, accessible</p>
      <div className="hero-btns">
        <Button
          className="btns"
          onClick={window.scrollTo(0, 0, "auto")}
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          link="/solutions/abotel"
        >
          Découvrez nos solutions
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
