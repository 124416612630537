import React from "react";
import "./Abonnements.css";

function Ipbxpabx() {
  return (
    <div className="abo-main-container">
      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--ipbx"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">IPBX</div>

              <p className="abo-card__text">
                IPBX (Standard téléphonique IP)<br></br>
                Xorcom <br></br>
                Une gamme complète de téléphone :<br></br>
                Bureau, Direction, Mural, Hôtelier, Sans-fil (DECT), Tactile,
                Portier audio, Conférence, Softphone
              </p>

              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>

        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--pabx"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">PABX</div>
              <p className="abo-card__text">
                PABX (Standard téléphonique traditionnel)<br></br>
                Mitel<br></br>
                Alcatel<br></br>
                Contrat de maintenance
              </p>

              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>

        <li className="abo-card__item">
          <div className="abo-card">
            <div className="abo-card__image card__image--recycle"></div>
            <div class="abo-card__content">
              <div className="abo-card__title">Standard Ecorecyclé</div>
              <p className="abo-card__text">
                IPBX ou PABX reconditionné<br></br>
                Xorcom<br></br>
                Mitel<br></br>
                Alcatel
              </p>
              <button class="card__btn card__btn--block">
                Détails de l'offre
              </button>
            </div>
          </div>
        </li>
      </ul>

      <ul className="abo-card__container">
        <li className="abo-card__item">
          <div className="abo-card">
            <div class="abo-card__content">
              <div className="abo-card__title">Les Plus</div>
              <p className="abo-card__text">
                Plug and Play : Branchez votre nouveau matériel et profitez
                immédiatement de ses fonctionnalités
                <br></br>
                De 1 à 200 utilisateurs<br></br>
                Large gamme de téléphone IP (couleur, son HD, tactile, sans fil,
                Android)<br></br>
                Préprogrammé selon vos besoins
              </p>
              <p className="abo-card__subtext">
                A partir de 690€ HT (payable en 3 x sans frais) ou à partir de
                1€ par jour et par utilisateur
              </p>
            </div>
          </div>
        </li>

        <li className="abo-card__item">
          <div className="abo-card">
            <div class="abo-card__content">
              <div className="abo-card__title">Inclus dans l'offre</div>
              <p className="abo-card__text">
                Messagerie personnalisée<br></br>
                Annuaire individuel et d'entreprise<br></br>
                Serveur vocal interractif<br></br>
                Prédécroché<br></br>
                Gestion des horaires manuelle ou automatique<br></br>
                Assisance et support<br></br>
                Renvoi d'appel<br></br>
                Gestion de files d'attente<br></br>
                Supervision des postes téléphoniques<br></br>
              </p>
              <p className="abo-card__subtext">
                *A l'heure<br></br>
                **Si souscription à un contrat de maintenance
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Ipbxpabx;
